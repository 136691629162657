<div class="align-items-center d-flex h-100 justify-content-center">
  <mat-card class="col-md-6">
    <mat-card-title>Login</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-form-field class="w-100">
          <input
            type="text"
            matInput
            placeholder="Email"
            formControlName="email"
          />
        </mat-form-field>

        <mat-form-field class="w-100">
          <input
            type="password"
            matInput
            placeholder="Password"
            formControlName="password"
          />
        </mat-form-field>

        <div class="button d-flex">
          <button type="button" routerLink="/register" mat-button>To sign up</button>
          <button type="submit" class="flex-grow-1" color="primary" mat-button>Login</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
