<div class="d-flex justify-content-end p-2">
  <button mat-fab color="primary" (click)="addData()">
    <mat-icon>add</mat-icon>
  </button>
</div>
<div class="table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
    class="w-100"
  >
    <!-- Position Column -->
    <ng-container matColumnDef="invoiceNo" sticky>
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by number"
      >
        Invoice No
      </th>
      <td mat-cell *matCellDef="let element">{{ element.invoiceNo }}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
    </ng-container>

    <!-- Company Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
      <td mat-cell *matCellDef="let element">{{ element.company }}</td>
    </ng-container>

    <!-- client Column -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
      <td mat-cell *matCellDef="let element">{{ element.client }}</td>
    </ng-container>

    <!-- Assignment Column -->
    <ng-container matColumnDef="assignment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Assignment</th>
      <td mat-cell *matCellDef="let element">{{ element.assignment }}</td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="startDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngStyle]="{ width: '150px' }"
      >
        Date
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{ width: '150px' }">
        <ng-container *ngIf="element.startDate">
          {{ element.startDate.toDate() | date }}
        </ng-container>
        -
        <ng-container *ngIf="element.endDate">
          {{ element.endDate.toDate() | date }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Place Column -->
    <ng-container matColumnDef="place">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Place</th>
      <td mat-cell *matCellDef="let element">
        {{ element.place }}
      </td>
    </ng-container>

    <!-- Interpreters Column -->
    <ng-container matColumnDef="interpreters">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Interpreters</th>
      <td mat-cell *matCellDef="let element">{{ element.interpreters }}</td>
    </ng-container>

    <!-- amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let element">
        {{ element.amount | currency : element.currency || 'TRY' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="isPaid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Paid</th>
      <td mat-cell *matCellDef="let element">
        <section class="example-section">
          <mat-checkbox
            class="example-margin"
            name="check-{{ element.id }}"
            [(ngModel)]="element.isPaid"
            (change)="this.updateValue(element)"
          ></mat-checkbox>
        </section>
      </td>
    </ng-container>

    <ng-container matColumnDef="options" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="this.editJob(element)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="this.removeJob(element)">
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
