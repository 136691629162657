<form [formGroup]="form" (ngSubmit)="submit()">
  <h1 mat-dialog-title>Add Job</h1>
  <div mat-dialog-content>
    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Invoice No"
        formControlName="invoiceNo"
      />
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Type"
        formControlName="type"
        [matAutocomplete]="autoType"
      />
      <mat-autocomplete autoActiveFirstOption #autoType="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions.type | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Assignment"
        formControlName="assignment"
        [matAutocomplete]="autoAssignment"
      />
      <mat-autocomplete autoActiveFirstOption #autoAssignment="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions.assignment | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Client"
        formControlName="client"
        [matAutocomplete]="autoClient"
      />
      <mat-autocomplete autoActiveFirstOption #autoClient="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions.client | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Company"
        formControlName="company"
        [matAutocomplete]="autoCompany"
      />
      <mat-autocomplete autoActiveFirstOption #autoCompany="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions.company | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          formControlName="startDate"
          placeholder="Start date"
          (click)="picker.open()"
        />
        <input
          matEndDate
          formControlName="endDate"
          placeholder="End date"
          (click)="picker.open()"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="form.controls.startDate.hasError('matStartDateInvalid')"
        >Invalid start date</mat-error
      >
      <mat-error *ngIf="form.controls.endDate.hasError('matEndDateInvalid')"
        >Invalid end date</mat-error
      >
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Place"
        formControlName="place"
        [matAutocomplete]="autoPlace"
      />
      <mat-autocomplete autoActiveFirstOption #autoPlace="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions.place | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Interpreters"
        formControlName="interpreters"
      />
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        type="text"
        matInput
        placeholder="Amount"
        formControlName="amount"
      />
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Currency</mat-label>
      <mat-select formControlName="currency">
        <mat-option
          *ngFor="let currency of this.currencies"
          [value]="currency.code"
          >{{ currency.name }} - ({{ currency.sign }})</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
    <div class="flex-grow-1"></div>
    <button
      [disabled]="!this.form.valid"
      type="submit"
      color="primary"
      mat-button
    >
      Save
    </button>
  </div>
</form>
